import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
 import i18n from "./lang";
import axios from './utils/axios';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
 const app = createApp(App);
 app.use(i18n).use(store).use(router).use(ElementPlus).mount("#app");
app.config.globalProperties.$axios = axios; 
