import { createRouter, createWebHashHistory,createWebHistory } from "vue-router";
import Home from "../views/Home/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
 //路由守卫
//  router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem('stor')
//   // const role = localStorage.getItem('ms_username');
//   // NProgress.start(); //进度条
//   if (to.path !== '/login' && !token) {
//     console.log('1')
//     next('/login');
//   } else {
//     console.log('2')
//     if (to.name === 'Login' && token) {
//       router.push('/')
//     } else {
//       next();
//     }
//   }
// });

export default router;
