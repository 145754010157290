
import { createI18n } from 'vue-i18n'
import zhLocale from './zh'
import enLocale from './en'

const i18n = createI18n({
  locale: 'en', //默认显示语言
  messages: {
    zh: {
      ...zhLocale,
    },
    en: {
      ...enLocale,
    }
  }
})

export default i18n
